import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import './landing.css';

// Importar imágenes
import logoRojo from '../assets/images/logo_rojo_transparente.png';
import heroImage from '../assets/images/1.svg';
import marketImage from '../assets/images/market.svg';
import logoBlanco from '../assets/images/logo_blanco_transparente.png';

// Array de comercios con tipos específicos
const comercios = [
  {
    id: 1,
    nombre: "Almacén Don José",
    direccion: "San Martín 2156, Casilda",
    horario: "Abierto ahora",
    id_lista: '2345-wer-45765',
    position: { lat: -33.044722, lng: -61.168592 },
    rating: 4,
    tipo: 'grocery'
  },
  {
    id: 2,
    nombre: "Verdulería El Jardín",
    direccion: "Buenos Aires 2450, Casilda",
    horario: "Abierto de 8:00 a 20:00",
    id_lista: '2346-wer-45766',
    position: { lat: -33.045722, lng: -61.167592 },
    rating: 4.5,
    tipo: 'grocery'
  },
  {
    id: 3,
    nombre: "Carnicería La Mejor",
    direccion: "Sarmiento 2234, Casilda",
    horario: "Abierto de 9:00 a 19:00",
    id_lista: '2347-wer-45767',
    position: { lat: -33.043722, lng: -61.169592 },
    rating: 5,
    tipo: 'butcher'
  }
];

// Variables de diseño
const theme = {
  colors: {
    primary: '#c73636',
    secondary: '#b7ff33',
    accent: '#FFB84D',
    dark: '#222222',
    light: '#F9FAFB',
    gray: {
      100: '#f8f9fa',
      600: '#6c757d',
      700: '#495057',
      800: '#343a40'
    }
  },
  gradients: {
    green: 'linear-gradient(180deg, #b7ff33 0%, rgba(219, 255, 153, 1) 100%)',
    red: 'linear-gradient(180deg, #c73636 0%, #a52929 100%)'
  }
};

// Componentes estilizados
const StyledHeader = styled('header')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.98)',
  boxShadow: '0 1px 10px rgba(0, 0, 0, 0.05)',
  zIndex: 1000,
  backdropFilter: 'blur(5px)'
}));

const StyledContainer = styled(Container)({
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '0 24px'
});

const HeaderContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1.25rem 0'
});

const Logo = styled('div')({
  '& img': {
    height: '100px'
  }
});

const MainNav = styled('nav')({
  '& ul': {
    display: 'flex',
    gap: '2.5rem',
    listStyle: 'none'
  },
  '& a': {
    color: theme.colors.gray[700],
    fontWeight: 500,
    textDecoration: 'none',
    position: 'relative',
    padding: '0.5rem 0',
    '&:hover': {
      color: theme.colors.primary
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: '2px',
      bottom: 0,
      left: 0,
      backgroundColor: theme.colors.primary,
      transition: 'width 0.3s ease'
    },
    '&:hover::after': {
      width: '100%'
    }
  }
});

const Button = styled('a')({
  display: 'inline-block',
  padding: '0.875rem 1.75rem',
  borderRadius: '50px',
  fontWeight: 600,
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  border: 'none',
  fontSize: '1rem',
  '&.btn-primary': {
    background: theme.gradients.green,
    color: theme.colors.dark,
    boxShadow: '0 4px 14px rgba(183, 255, 51, 0.3)',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 6px 20px rgba(183, 255, 51, 0.4)',
      filter: 'brightness(1.05)'
    }
  },
  '&.btn-large': {
    padding: '1.125rem 2.25rem',
    fontSize: '1.1rem'
  }
});

const HeroSection = styled('section')({
  padding: '10rem 0 7rem',
  backgroundColor: theme.colors.light,
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '600px',
    height: '600px',
    borderRadius: '50%',
    background: 'linear-gradient(135deg, rgba(183, 255, 51, 0.1), rgba(219, 255, 153, 0.1))',
    top: '-200px',
    right: '-200px',
    zIndex: 0
  }
});

export const Landing = () => {
  const navigate = useNavigate();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY,
    libraries: ['places']
  });

  const [selectedComercio, setSelectedComercio] = useState(null);
  const [map, setMap] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [isLoadingLocation, setIsLoadingLocation] = useState(true);
  const [center, setCenter] = useState({
    lat: -33.044722,
    lng: -61.168592
  });

  // Obtener la ubicación del usuario antes de cargar el mapa
  useEffect(() => {
    if (navigator.geolocation) {
      setIsLoadingLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userPos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          setUserLocation(userPos);
          setCenter(userPos); // Centrar el mapa en la ubicación del usuario
          setIsLoadingLocation(false);
        },
        (error) => {
          console.error('Error obteniendo la ubicación:', error);
          setIsLoadingLocation(false);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    } else {
      setIsLoadingLocation(false);
    }
  }, []); // Se ejecuta solo al montar el componente

  // Ícono personalizado para la ubicación del usuario
  const userLocationIcon = useMemo(() => {
    if (!isLoaded || !window.google) return null;
    
    const svgMarker = {
      path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
      fillColor: "#4285F4",
      fillOpacity: 1,
      strokeColor: "#FFFFFF",
      strokeWeight: 2,
      rotation: 0,
      scale: 2,
      anchor: new window.google.maps.Point(12, 22),
      labelOrigin: new window.google.maps.Point(12, 10)
    };

    return svgMarker;
  }, [isLoaded]);

  const renderStars = useCallback((rating) => {
    return "★".repeat(Math.floor(rating)) + "☆".repeat(5 - Math.floor(rating));
  }, []);

  const getTipoLabel = useCallback((tipo) => {
    const labels = {
      'restaurant': 'Restaurante',
      'grocery': 'Almacén',
      'bakery': 'Panadería',
      'butcher': 'Carnicería'
    };
    return labels[tipo] || 'Comercio';
  }, []);

  const navigateToCatalogo = useCallback((id_lista) => {
    navigate(`/catalogo/${id_lista}`);
  }, [navigate]);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedComercio(null);
  }, []);

  const handleMarkerClick = useCallback((comercio) => {
    setSelectedComercio(comercio);
    if (map) {
      map.panTo(comercio.position);
    }
  }, [map]);

  const getMarkerIconByType = useMemo(() => (tipo) => {
    if (!isLoaded || !window.google) return null;
    
    const iconBase = 'https://maps.google.com/mapfiles/ms/icons/';
    const iconUrls = {
      'restaurant': iconBase + 'red-dot.png',
      'grocery': iconBase + 'green-dot.png',
      'bakery': iconBase + 'yellow-dot.png',
      'butcher': iconBase + 'blue-dot.png',
      'default': iconBase + 'purple-dot.png'
    };

    return {
      url: iconUrls[tipo] || iconUrls.default,
      scaledSize: new window.google.maps.Size(40, 40),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(20, 40)
    };
  }, [isLoaded]);

  const onLoad = useCallback((map) => {
    if (isLoaded && window.google && !isLoadingLocation) {
      const bounds = new window.google.maps.LatLngBounds();
      
      // Primero agregamos la ubicación del usuario si está disponible
      if (userLocation) {
        bounds.extend(userLocation);
      }
      
      // Luego agregamos los comercios
      comercios.forEach(comercio => bounds.extend(comercio.position));
      
      map.fitBounds(bounds);
      
      // Ajustamos el zoom después de un pequeño delay
      setTimeout(() => {
        const zoom = map.getZoom();
        map.setZoom(Math.min(zoom, 15)); // No permitir un zoom mayor a 15
      }, 100);
    }
    setMap(map);
  }, [isLoaded, userLocation, isLoadingLocation]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  if (!isLoaded || isLoadingLocation) {
    return <div className="loading-stores">Cargando mapa y ubicación...</div>;
  }

  return (
    <Box>
      <StyledHeader>
        <StyledContainer>
          <HeaderContent>
            <Logo>
              <img src={logoRojo} alt="Market by Mayah Logo" />
            </Logo>
            <MainNav>
              <ul>
                <li><a href="#como-funciona">¿Cómo funciona?</a></li>
                <li><a href="#beneficios">Beneficios</a></li>
                <li><a href="#explorar">Explorar</a></li>
                <li><a href="comercios.html">Soy Comercio</a></li>
                <li><a href="#faq">FAQ</a></li>
              </ul>
            </MainNav>
            <div className="header-buttons">
              <Button href="#explorar" className="btn-primary">Empezar a pedir</Button>
            </div>
            <button className="mobile-menu-toggle" aria-label="Menú">
              <span></span>
              <span></span>
              <span></span>
            </button>
          </HeaderContent>
        </StyledContainer>
      </StyledHeader>

      <main>
        {/* Hero Section */}
        <HeroSection>
          <div className="container">
            <div className="hero-content">
              <h1>Compra en tu barrio y retira tu pedido cuando quieras</h1>
              <p className="hero-description">Market te conecta con los comercios de tu barrio. Haces tu pedido online y lo retiras cuando te convenga. <span className="highlight-red">Sin comisiones, sin precios ocultos.</span></p>
              <div className="hero-cta">
                <a href="#explorar" className="btn btn-primary btn-large">Explorar comercios</a>
                <a href="#impacto" className="btn btn-outline btn-large">Ver beneficios</a>
              </div>
            </div>
            <div className="hero-image">
              <img src={heroImage} alt="Ilustración de servicio de entrega" />
            </div>
          </div>
        </HeroSection>

        {/* How it Works Section */}
        <section id="como-funciona" className="how-it-works">
          <div className="container">
            <h2 className="section-title">¿Cómo funciona?</h2>
            <div className="steps">
              <div className="step">
                <div className="step-icon">
                  <span>🔍</span>
                </div>
                <h3>Explora negocios cerca tuyo</h3>
                <p>Descubre comercios locales y navega sus catálogos con precios reales, idénticos a los de la tienda física.</p>
              </div>
              <div className="step">
                <div className="step-icon">
                  <span>📝</span>
                </div>
                <h3>Haz tu pedido anticipado</h3>
                <p>Ordena online y paga directamente en el comercio cuando retires tu pedido. Sin comisiones ni pagos por adelantado.</p>
              </div>
              <div className="step">
                <div className="step-icon">
                  <span>🛍️</span>
                </div>
                <h3>Retira sin esperas</h3>
                <p>Llega al comercio y recoge tu pedido ya preparado. Sin filas, sin demoras, y pagas directamente al comerciante.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Map Section */}
        <section id="explorar" className="map-section">
          <div className="container">
            <h2 className="section-title">Explora comercios cercanos</h2>
            <p className="map-description">Descubre los comercios de tu barrio y haz tus pedidos para retirar cuando quieras.</p>
            
            <div className="map-container">
                
            { !isLoaded ?
                <div className="loading-stores">Cargando mapa...</div>
                :
                <GoogleMap
                    mapContainerClassName="map"
                    center={center}
                    zoom={15}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    options={{
                    styles: [
                        {
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [{ visibility: "off" }]
                        }
                    ],
                    disableDefaultUI: false,
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: true,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    gestureHandling: 'greedy' // Permite zoom con un solo dedo en móviles
                    }}
                >
                    {/* Marcador de la ubicación del usuario siempre visible */}
                    {userLocation && (
                    <Marker
                        position={userLocation}
                        icon={userLocationIcon}
                        title="Tu ubicación"
                        label={{
                        text: "Tú",
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold"
                        }}
                        zIndex={2000}
                    />
                    )}

                    {/* Marcadores de los comercios */}
                    {comercios.map((comercio) => (
                    <Marker
                        key={comercio.id}
                        position={comercio.position}
                        title={comercio.nombre}
                        onClick={() => handleMarkerClick(comercio)}
                        icon={getMarkerIconByType(comercio.tipo)}
                        animation={window.google.maps.Animation.DROP}
                        options={{
                        optimized: false,
                        visible: true,
                        zIndex: 1000
                        }}
                    />
                    ))}

                    {selectedComercio && (
                    <InfoWindow
                        position={selectedComercio.position}
                        onCloseClick={handleInfoWindowClose}
                        options={{
                        pixelOffset: new window.google.maps.Size(0, -40)
                        }}
                    >
                        <div className="store-info">
                        <h3>{selectedComercio.nombre}</h3>
                        <p className="store-type">{getTipoLabel(selectedComercio.tipo)}</p>
                        <div className="store-rating">
                            <span className="rating-stars" style={{ color: '#FFB84D' }}>{renderStars(selectedComercio.rating)}</span>
                            <span className="rating-value">{selectedComercio.rating}</span>
                        </div>
                        <p className="store-address">{selectedComercio.direccion}</p>
                        <p className="store-status open">{selectedComercio.horario}</p>
                        <button 
                            className="btn-view-store"
                            onClick={() => navigateToCatalogo(selectedComercio.id_lista)}
                        >
                            Ver productos
                        </button>
                        </div>
                    </InfoWindow>
                    )}
                </GoogleMap>
            }
              <div className="map-sidebar">
                <div className="map-search">
                  <input type="text" id="store-search" placeholder="Buscar comercios..." />
                  <button className="search-btn"><i className="icon-search"></i></button>
                </div>
                <div className="map-filters">
                  <button className="filter-btn active" data-filter="all">Todos</button>
                  <button className="filter-btn" data-filter="restaurant">Restaurantes</button>
                  <button className="filter-btn" data-filter="grocery">Almacenes</button>
                  <button className="filter-btn" data-filter="bakery">Panaderías</button>
                  <button className="filter-btn" data-filter="butcher">Carnicerías</button>
                </div>
                <div className="stores-list">
                  {comercios.map((comercio) => (
                    <div 
                      key={comercio.id} 
                      className="store-card"
                      onClick={() => {
                        handleMarkerClick(comercio);
                      }}
                    >
                      <h3>{comercio.nombre}</h3>
                      <div className="store-rating">
                        <span className="rating-stars">{renderStars(comercio.rating)}</span>
                        <span className="rating-value">{comercio.rating}</span>
                      </div>
                      <p className="store-address">{comercio.direccion}</p>
                      <p className="store-status open">{comercio.horario}</p>
                      <button 
                        className="btn-view-store"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigateToCatalogo(comercio.id_lista);
                        }}
                      >
                        Ver productos
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Join Market Section */}
        <section id="unete" className="join-market">
          <div className="container">
            <div className="join-content">
              <div className="join-text">
                <h2>Únete a Market by Mayah</h2>
                <p>Si tienes un comercio y quieres aumentar tu visibilidad sin pagar comisiones, Market es para ti. Conecta con clientes de tu barrio y haz crecer tu negocio.</p>
                <a href="comercios.html" className="btn btn-primary btn-large">Registra tu comercio</a>
              </div>
              <div className="join-image">
                <img src={marketImage} alt="Comerciante usando Market by Mayah" />
              </div>
            </div>
          </div>
        </section>

        {/* Footer */}
        <footer className="footer">
          <div className="container">
            <div className="footer-content">
              <div className="footer-logo">
                <img src={logoBlanco} alt="Market by Mayah Logo" />
                <p>Compra en tu barrio, sin comisiones ni envíos.</p>
              </div>
              <div className="footer-links">
                <div className="footer-column">
                  <h4>Market</h4>
                  <ul>
                    <li><a href="#como-funciona">¿Cómo funciona?</a></li>
                    <li><a href="#beneficios">Beneficios</a></li>
                    <li><a href="#explorar">Explorar</a></li>
                    <li><a href="comercios.html">Soy Comercio</a></li>
                    <li><a href="#faq">FAQ</a></li>
                  </ul>
                </div>
                <div className="footer-column">
                  <h4>Legal</h4>
                  <ul>
                    <li><a href="terminos.html">Términos y condiciones</a></li>
                    <li><a href="privacidad.html">Política de privacidad</a></li>
                    <li><a href="cookies.html">Política de cookies</a></li>
                  </ul>
                </div>
                <div className="footer-column">
                  <h4>Contacto</h4>
                  <ul>
                    <li><a href="mailto:info@marketbymayah.com">info@marketbymayah.com</a></li>
                    <li><a href="tel:+5491123456789">+54 9 11 2345-6789</a></li>
                    <li className="social-links">
                      <a href="#" aria-label="Facebook"><i className="icon-facebook"></i></a>
                      <a href="#" aria-label="Instagram"><i className="icon-instagram"></i></a>
                      <a href="#" aria-label="Twitter"><i className="icon-twitter"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <p>&copy; 2025 Market by Mayah. Todos los derechos reservados.</p>
              <p className="mayah-powered">Tecnología de gestión de pedidos <a href="https://mayah.com.ar/" target="_blank" rel="noopener">Mayah</a></p>
            </div>
          </div>
        </footer>
      </main>
    </Box>
  );
}; 