// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/store'; // Importa store y persistor desde tu archivo store.js
import { addNotification } from './store/notificationSlice';
import { CssBaseline } from '@mui/material';

import { Home } from './pages/Home';
import { ProductScan } from './pages/ProductScan';
import { InventoryManagement } from './pages/InventoryManagement';
import { Login } from './pages/Login';
import { Signup } from './pages/Signup';
import { Layout } from './components/Layout';
import { Landing } from './pages/Landing';
import ResetPass from './pages/ResetPass';

const PUBLIC_KEY = 'BLFP82lfqGU5HvxBA3osKVUgmOkWv6kWSJGHncgbh_VYbSI90ByWJLIe7PpJoCyqlpszaWIORqHR-NV4rfGT-yg'

function App() {
  const dispatch = useDispatch();
  const PrivateRoute = ({ children }) => {
    const isAuthenticated = useSelector(state => !!state.auth.user); // Esta línea verifica el estado de autenticación
    console.log(isAuthenticated)
    if (!isAuthenticated) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  const urlBase64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  }
  
  useEffect(() => {
    const registerServiceWorker = async () => {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const registration = await navigator.serviceWorker.register('/service-worker.js');
        try {
          await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(PUBLIC_KEY)
          });
          
          // Here you would typically send the subscription object to your server
        } catch (error) {
          console.error('Error subscribing to push:', error);
        }
      }
    };
    registerServiceWorker();
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', function(event) {
        if (event.data && event.data.type === 'NEW_NOTIFICATION') {
          const notification = event.data.payload;
          dispatch(addNotification(notification));
        }
      });
    }
  }, [dispatch]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <CssBaseline>
        <Router>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/home" element={<PrivateRoute><Layout><Home /></Layout></PrivateRoute>} />
            <Route path="/product-scan" element={<PrivateRoute><Layout><ProductScan /></Layout></PrivateRoute>} />
            <Route path="/inventory/:index" element={<PrivateRoute><Layout><InventoryManagement /></Layout></PrivateRoute>} />
            <Route path="/inventory" element={<Navigate to="/inventory/0" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<ResetPass />} />
          </Routes>
        </Router>
        </CssBaseline>
      </PersistGate>
    </Provider>
  );
}

export default App;